<template>
  <div>
    <tw-header :title="$t('BreadCrumbs.L/C List')"></tw-header>
    <div class="tw_container">
      <tw-breadcrumbs :pages="breadcrumbs" style="margin-bottom: 40px" />
      <tw-entity-buttons />

      <el-form class="search_unit compact" ref="searchUnit" :model="searchForm" label-position="top">
        <div class="row">
          <el-form-item :label="$t('Label.Trading ID / Flow ID')" prop="tradingFlowId">
            <el-input type="text" v-model.trim="searchForm.tradingFlowId" placeholder="Enter keyword" class="trading-flow"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Trading Name')" prop="tradingName">
            <el-input type="text" v-model="searchForm.tradingName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Flow Name')" prop="flowName">
            <el-input type="text" v-model="searchForm.flowName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Process')" prop="processTypes">
            <el-select v-model="searchForm.processTypes" :class="{ multiple_selected: multipleSelect(searchForm.processTypes) }" placeholder="Select" multiple collapse-tags clearable>
              <el-option
                v-for="process in processes"
                :key="process.name"
                :label="process.name"
                :value="process.processType"
              >
                <span>{{ process.name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Label.Process User Memo')" prop="processUserMemo">
            <el-input type="text" v-model="searchForm.processUserMemo" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <tw-button type="secondary" size="small" class="toggle_button" :class="{ open: openForm }" @click="openToggle">
            <img src="@/assets/images/icons/view_all.svg">
          </tw-button>
        </div>

        <transition
          name="content"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
          @after-leave="afterLeave"
        >
          <div v-show="openForm">
            <div class="row">
              <el-form-item :label="$t('Label.L/C No')" prop="lcNo">
                <el-input type="text" v-model="searchForm.lcNo" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.L/C Date')" prop="lcDate">
                <el-date-picker
                  v-model="searchForm.lcDate"
                  type="daterange"
                  range-separator="to"
                  format="yyyy-MM-dd"
                  :default-value="defaultDate"
                  @change.native="onDateRangeChange"
                  start-placeholder="Start date"
                  end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('Label.Section(From)')" prop="fromSectionIds">
                <TwSelectSection v-model="searchForm.fromSectionIds" :sections="fromSections" />
              </el-form-item>
              <el-form-item :label="$t('Label.Section(To)')" prop="toSectionIds">
                <TwSelectSection v-model="searchForm.toSectionIds" :sections="toSections" />
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item :label="$t('Label.L/C Applicant')" prop="applicant">
                <el-input type="text" v-model="searchForm.applicant" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Advising Bank')" prop="advisingBankName">
                <el-input type="text" v-model="searchForm.advisingBankName" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Main Goods')" prop="mainGoods">
                <el-input type="text" v-model="searchForm.mainGoods" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Status')" prop="processProgressStatus" style="margin-right: 40px">
                <el-select v-model="searchForm.processProgressStatus" :class="{multiple_selected: multipleSelect(searchForm.processProgressStatus)}" placeholder="Select" multiple collapse-tags clearable>
                  <el-option
                    v-for="status in PROCESS_PROGRESS_STATUS_VARIABLES"
                    :key="status.code"
                    :label="status.label"
                    :value="status.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </transition>

        <div class="row drop space_between">
          <div>
            <tw-button
              type="secondary"
              size="medium"
              icon="search"
              @click="search"
            >
              Search
            </tw-button>
            <tw-button
              type="default"
              size="medium"
              @click="clear"
              style="margin: 8px 16px 16px;"
            >
              Clear
            </tw-button>
          </div>
          <el-dropdown trigger="click" placement="bottom" @command="onDropDownClick" :tabindex="-1">
            <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
            <el-dropdown-menu class="nowrap">
              <el-dropdown-item command="tsv">{{$t('Label.TSV Download')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-form>

      <tw-table-system :schemas="schemas" :items="items" :pageSize="limit" :totalCount="totalCount" :offset="offset" :selection="selection" :serverPagination="true" @row-click="rowClick" @row-dbclick="rowDbClick" @paging="onPaging" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import { LC_ACCEPT_SEARCH_SORT_TARGET, DEFAULT_PAGENATION, ENTITY_TYPE } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import mixinEntity from '@/utils/mixinEntity.js';
import { collapseTransition } from '@/utils/nextFrame';
import { clearSearchForm } from '@/utils/searchUtil.js';
import schemas from '@/dictionaries/listLcSchema.json';

const ENTITY_NAME = 'LC'

export default {
  name: 'ListLC',
  mixins: [mixinEntityList, collapseTransition, mixinEntity],
  data() {
    return {
      entityCode: ENTITY_TYPE.LC,
      schemas: schemas,
      items: null,
      searchForm: {
        tradingFlowId: '',
        tradingName: '',
        flowName: '',
        lcNo: '',
        lcDate: '',
        fromSectionIds: [],
        toSectionIds: [],
        applicant: '',
        advisingBankName: '',
        mainGoods: '',
        processProgressStatus: [],
        processTypes: [],
        processUserMemo: "",
      },
      limit: DEFAULT_PAGENATION.LIMIT,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      lastQuery: {},
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
        {label: this.$t('BreadCrumbs.L/C List')},
      ];
    },
  },
  created() {
    const queries = this.$store.getters.getQueries(ENTITY_NAME);
    if (queries) {
      this.searchForm = queries;
    }
    this.fetch();
    this.processes = this.getProcessesByEntity(ENTITY_NAME);
  },
  methods: {
    // POSTパラメータを生成します
    transformQueries() {
      const f = this.searchForm;
      const queries = {
        tradingId: this.getTradingFlowId(f.tradingFlowId)[0],
        tradingFlowId: this.getTradingFlowId(f.tradingFlowId)[1],
        tradingName: f.tradingName,
        flowName: f.flowName,
        lcNo: f.lcNo,
        dateOfIssueFrom: f.lcDate && f.lcDate.length ? dayjs(this.getStartDate(f.lcDate)).format('YYMMDD') : null,
        dateOfIssueTo: f.lcDate && f.lcDate.length ? dayjs(this.getEndDate(f.lcDate)).format('YYMMDD') : null,
        fromSectionIds: f.fromSectionIds,
        toSectionIds: f.toSectionIds,
        applicant: f.applicant,
        advisingBankName: f.advisingBankName,
        mainGoods: f.mainGoods,
        processProgressStatus: this.getProcessProgressStatus(f.processProgressStatus),
        processIds: f.processTypes ? _.flatten(_.map(f.processTypes, item => this.getProcessIdsByProcessType(item))) : [],
        processUserMemo: f.processUserMemo,
      };

      return {
        ...queries,
        initialFlag: this.initialFlag(queries),
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
    },
    fetch(queries) {
      this.cancelRequestSources.forEach(tag => {
        $api.cancelRequests(tag);
      });
      // bff_lc_1 LC接受一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/lc-acceptance/search',
          query: queries || this.transformQueries()
        },
        tag: this.pushCancelTag(),
      };

      this.lastQuery = _.cloneDeep(params.lslConfig.query);

      $api.request(params)
      .then(res => {
        this.items = res.lcAcceptance;
        this.totalCount = res.totalCount;
      })
      .catch(err => {
        if (err.isCanceled) {
          return;
        }
        this.items = [];
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    search() {
      this.offset = 0;
      this.$store.commit('SET_QUERIES', {key: ENTITY_NAME, queries: _.cloneDeep(this.searchForm)});
      this.fetch();
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.$store.commit('SET_QUERIES', {key: ENTITY_NAME, queries: _.cloneDeep(this.searchForm)});
    },
    onPaging({target, sortType, currentPage}) {
      if (target && sortType) {
        const key = _.get(_.find(this.schemas, {key: target}), 'sortTarget');
        this.sort = {
          target: [LC_ACCEPT_SEARCH_SORT_TARGET[key] || target],
          sortType: [sortType],
        };
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      const queries = {
        ...this.lastQuery,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
      this.fetch(queries);
    },
    // TSVダウンロード用に全件取得します
    fetchAll() {
      return new Promise((resolve, reject) => {
        // bff_lc_1 LC接受一覧検索BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'get_/v1/lc-acceptance/search',
            query: { ...this.transformQueries(), offset: undefined, limit: undefined }
          }
        };

        $api.request(params)
        .then(res => {
          resolve(res.lcAcceptance);
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          reject();
        });
      });
    }
  }
};
</script>
